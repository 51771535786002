// Generic imports
import React from "react"

// Other components imports
import { Styles } from "components/SearchNotFound/style"
// import Button from "components/Button"
import Image from "components/image"

// Redux imports

// Static imports

// Utils imports

function SearchNotFound({ keyword, ...props }) {
  return (
    <Styles {...props}>
      <div className="tempat-waktu-desc">
        <h4>Kelas dengan keyword {keyword} tidak ditemukan</h4>
        {/* <div className="tw-longdesc">
            Beri tahu kami kelas apa yang Anda inginkan. Kami akan
            menggunakannya sebagai masukan untuk kelas baru.
          </div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSd8pMSv6GKWxXZ_ahHJh9se8OJeyExmoRfxTq3RIc-cJ0NN1g/viewform?usp=sf_link">
            <Button>REQUEST CLASS</Button>
          </a> */}
      </div>
      <div className="tempat-waktu-bg">
        <Image imgName="info_tidak-menemukan.png" alt="tidak-menemukan" />
      </div>
    </Styles>
  )
}

export default SearchNotFound
