// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  margin: 8px;

  .card-container {
    background: white;
    border: 1px solid #cacccf;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    overflow-wrap: break-word;
  }

  .class-content {
    margin: 1rem 1rem;
    margin-bottom: 0.5rem;
    width: calc(100% - 225px);
  }

  .card-header {
    background: white;
    display: flex;
    flex-direction: row;
    border: 0;
    align-items: center;
    padding: 0;
  }

  .card-header a {
    color: black;
    text-decoration: none;
    font-style: normal;
  }

  .card-header h4 {
    text-align: left;
    font-weight: 100;
  }

  .class-type {
    width: 75px;
    margin-left: 8px;
    transition: transform 0.2s;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .class-type:hover {
    transform: translate(0, -8px);
  }

  .extras {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-bottom: 8px;
  }

  .class-author a {
    color: #54b2e5;
    cursor: default;
  }

  @media (max-width: 767px) {
    .card-container {
      display: flex;
      flex-wrap: wrap;

      & > * {
        width: 100%;
      }
    }
  }
`
