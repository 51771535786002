import React from "react"
import { Styles } from "./style"
import { Link } from "gatsby"
import Button from "../../Button"
import Image from "../../image"

import { trimDescription } from "services/utils"
import { sendAmplitudeData } from "trackers/amplitude"

function OldClassCard(props) {
  const classType =
    props.type === "TS"
      ? "TamanClass Seikhlasnya"
      : props.type === "TP"
      ? "TamanClass+"
      : props.type === "AP"
      ? "AfterClass+"
      : "AfterClass Seikhlasnya"
  return (
    <Styles>
      <div className="card-container">
        <div
          className="class-image"
          style={{
            backgroundImage: `url(${props.image})`,
          }}
        ></div>
        <div className="class-content">
          <Link
            onClick={() => {
              sendAmplitudeData("CLASSES_LIHAT_DETIL_KELAS_TITLE_CLICKED")
            }}
            to={`/kelas/${props.code}/`}
          >
            <span
              className={
                props.type === "TP" ? "tamanclassplus" : "tamanclassseikhlasnya"
              }
            ></span>
            <h4>
              {props.title}{" "}
              <div
                className={
                  props.is_verified
                    ? "small-verified-show"
                    : "small-verified-unshow"
                }
              >
                <Image imgName="verified.png" alt="verified" />
              </div>
            </h4>
          </Link>
          <p className="author">
            by <a>{props.author}</a>
          </p>
          <p className="content-description">
            {trimDescription(props.description)}
          </p>
          {props.children}
          <div className="btn-detail">
            <Button
              onClick={() => {
                sendAmplitudeData("CLASSES_LIHAT_DETIL_KELAS_BTN_CLICKED")
              }}
              to={`/kelas/${props.code}/`}
            >
              Lihat Detil Kelas
            </Button>
          </div>
          <div className="class-tags">
            {props.tags.map((tag, idx) => (
              <div className="class-tag" key={idx}>
                <Button onClick={props.onClickTag(tag)}>
                  {tag.length > 30 ? tag.slice(0, 27) + "..." : tag}
                </Button>
              </div>
            ))}
            <div className="class-tag">
              <Button onClick={() => props.onClickTipe(props.type)}>
                {classType}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  )
}

export default OldClassCard
