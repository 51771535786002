// Generic imports
import styled from "styled-components"

// Static imports
import bgImage from "images/classes-top2.png"

export const Styles = styled.div`
  background: url(${bgImage}) no-repeat center center,
    radial-gradient(
        35.18% 116.24% at 95.71% 14.98%,
        #d1f6c4 0%,
        rgba(255, 254, 252, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
      115.39% 131.57% at -8.46% 121.2%,
      rgba(60, 181, 159, 0.4) 0%,
      #fffefc 100%
    );
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 40vh;
  color: #333333;

  h1 {
    max-width: 550px;
    font-weight: 700;
    font-size: 48px;
    padding-bottom: 5px;
  }

  .judul {
    max-width: 1280px;
    width: 100%;
    margin: auto;
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .daftar {
    font-size: 1.1em;
    letter-spacing: 0.5px;
    max-width: 500px;
    padding-bottom: 60px;
    font-weight: 700;
  }

  .btn-one button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .btn-two button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 30px;
    background: transparent;
    color: #51b848;
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    .judul {
      padding-top: 90px;
    }
  }
`
