// Generic imports
import React, { Component } from "react"

// Other components imports
import { Styles } from "components/Classes/ClassesTop/style"

// Redux imports

// Static imports

// Utils imports

class ClassesTop extends Component {
  render() {
    return (
      <Styles>
        <div className="judul">
          <h1>TamanClass untukmu.</h1>
          <div className="daftar">
            Daftar di kelas kami dan jadilah yang terdepan dalam menguasai
            materi!
          </div>
        </div>
      </Styles>
    )
  }
}

export default ClassesTop
