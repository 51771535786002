// Generic imports
import React, { Component } from "react"

// Other components imports
import ClassesTop from "components/Classes/ClassesTop"
import SearchBox from "components/Classes/SearchBox"
import { Styles } from "components/Classes/style"

// Redux imports

// Static imports

// Utils imports

class Classes extends Component {
  render() {
    const { whatsOn } = this.props
    return (
      <Styles>
        <ClassesTop />
        <div className="search-section">
          <SearchBox whatsOn={whatsOn} />
        </div>
      </Styles>
    )
  }
}

export default Classes
