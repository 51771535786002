// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  .section-container {
    max-width: 1280px;
    min-width: 75vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .subtitle {
    font-size: 24px;
    color: #424242;
    font-weight: bold;
  }

  .categories-search-box {
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;

    cursor: pointer;
    font-weight: bold;
    color: #3b3b3b;

    .categories-icon-wrapper {
      position: relative;
      margin-right: 8px;
      padding-bottom: 2px;
    }
  }

  .categories-search-box:hover {
    opacity: 0.7;
  }

  .search-container {
    width: 100%;
    padding: 35px 32px 45px 32px;
    margin-top: -15vh;
    background: #ffffff;
    border-radius: 16px;
    filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.1));

    .categories-title {
      color: #424242;
      font-weight: bold;

      display: flex;
      align-items: center;
      margin-right: 0.25rem;
    }

    .categories-wrapper {
      display: flex;

      margin: 0 4px;
      padding: 8px 12px;
      border: 1px solid #818181;
      border-radius: 24px;
      color: #818181;

      &:hover {
        background: #f5fff5;
        color: #33a629;
        border: 1px solid #33a629;
        cursor: pointer;
      }

      .categories-icon-wrapper {
        position: relative;
        margin-right: 8px;
      }

      .categories-icon {
        width: 16px;
        height: auto;
        fill: #818181;
      }

      .categories-icon:hover {
        fill: #33a629;
      }
    }

    .active {
      background: #f5fff5;
      color: #33a629;
      border: 1px solid #33a629;
      cursor: pointer;
    }
  }

  .search-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.5rem;
    /* margin-top: -5rem; */
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
  }

  .card-class {
    margin: 1%;
    width: calc(25% - 2%);
    max-width: 320px;
    min-height: 280px;
  }

  .class-dropdown,
  .dropdown-item {
    font-size: 1.25rem;
  }

  .img-dropdown {
    height: 37.6px;
    padding-top: 8px;
  }

  .class-dropdown {
    height: 60px;
    width: 180px;
    padding-left: 1rem;
    border: 1px solid #cacccf;
    box-sizing: border-box;
    border-radius: 16px 0 0 16px;
    border-right: 0;
    background-color: #e8e8e8;
    text-align: left;
    color: black;
    overflow-x: hidden;
  }

  .class-dropdown:focus {
    box-shadow: none;
  }

  .dropdown-item:focus {
    background: #51b848;
    color: white;
  }

  .dropdown-toggle:after {
    vertical-align: 0.15em;
    margin-left: 6px;
  }

  option {
    background-color: white;
  }

  .class-search {
    background: #f7f7f7;
    height: 52px;
    width: 100%;
    padding: 0.75rem 1.25rem;
    /* border: 1px solid #cacccf; */
    border: 0px;
    border-right: 0;
    box-sizing: border-box;
    /* border-radius: 0; */
    border-radius: 12px 0 0 12px;
  }

  input[type="text"]::placeholder {
    color: #cacccf;
  }

  input[type="text"]::-webkit-input-placeholder {
    color: #cacccf;
  }

  .looking-glass {
    background: #f7f7f7;
    height: 52px;
    width: 60px;
    /* border: 1px solid #cacccf; */
    border: 0px;
    border-left: 0;
    box-sizing: border-box;
    border-radius: 0 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 1px;
  }

  .looking-img-wrapper {
    width: 16px;
    height: 16px;
  }

  .tamanclass {
    height: 25px;
    width: 25px;
    background-color: #54b2e5;
    border-radius: 50%;
    float: right;
    margin-top: 0.25rem;
  }

  .tamanclassplus {
    height: 25px;
    width: 25px;
    background-color: #f8d057;
    border-radius: 50%;
    float: right;
    margin-top: 0.25rem;
  }

  .page-link {
    color: #52575c;
    margin-right: 0.5rem;
    border-radius: 0.25rem;
  }

  .page-item:last-child .page-link {
    margin-right: 0;
  }

  .page-item.active .page-link {
    background-color: #51b848;
    border-color: #51b848;
  }

  .pagination {
    align-self: flex-end;
    padding-top: 2rem;
  }

  .not-found {
    padding-top: 5rem;
  }

  .whatson-container {
    overflow-x: visible;
  }

  .whatson-image {
    width: 50%;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    .class-dropdown {
      width: 180px;
      height: 45px;
    }

    .card-class {
      margin: 1.25%;
      width: calc(33% - 2.5%);
    }

    .class-search {
      width: 100%;
      height: 45px;
    }

    .looking-glass {
      width: 60px;
      height: 45px;
    }

    .looking-img-wrapper {
      width: 25px;
      height: 25px;
    }

    .img-dropdown {
      height: 32px;
      padding-top: 3px;
      padding-bottom: 1px;
    }

    .whatson-container {
      overflow-x: scroll;
    }

    .whatson-image {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .class-dropdown {
      width: 140px;
    }

    .search-container {
      margin-top: -10vh;
    }

    .card-class {
      margin: 1.5%;
      width: calc(50% - 3%);
    }

    .class-search {
      width: calc(95vw - 200px);
    }

    .class-search,
    .class-dropdown,
    .dropdown-item {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    .card-list {
      justify-content: center;
    }

    .card-class {
      margin: 2%;
      width: calc(100% - 4%);
    }
  }

  /* @media (min-width: 1000px) {
    .mid-xl {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }

  @media (min-width: 1200px) {
    .mid-xl {
      flex: 0 0 25%;
      max-width: 25%;
    }
  } */
`
