// Generic imports
import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  display: inline-block;
  width: 100%;

  label {
    color: #51b848;
  }

  textarea {
    width: 100%;
  }
`

export default function Textarea(props) {
  const { label, id, ...otherProps } = props
  const inputId = id !== null ? id : props.name

  return (
    <Styles className="taman-input">
      {label ? <label htmlFor={inputId}>{label}</label> : <></>}
      <textarea id={inputId} {...otherProps} />
    </Styles>
  )
}
