// Generic imports
import React, { useEffect, useState } from "react"

// Other components imports
import Classes from "components/Classes"
import Layout from "components/layout"
import SEO from "components/seo"
import { getAllClassWhatsOn } from "../services/kelas"

// Redux imports

// Static imports

// Utils imports

const ClassesPage = () => {
  const [classWhatsOn, setclassWhatsOn] = useState([])

  useEffect(() => {
    getAllClassWhatsOn().then(result => {
      setclassWhatsOn(result.data)
    })
  }, [])

  return (
    <Layout>
      <SEO title="Classes" />
      <Classes whatsOn={classWhatsOn} />
    </Layout>
  )
}

export default ClassesPage
