// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  .search-section {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`
