// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  max-width: calc(1280px * 2 / 3);
  padding-bottom: 15px;
  display: flex;
  align-items: center;

  .tempat-waktu-bg {
    width: 40%;
  }

  .tempat-waktu-desc {
    width: 60%;
    padding: 50px;
    padding-left: 100px;
  }

  .tempat-waktu-desc button {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 25px;
  }

  .tw-longdesc {
    width: 80%;
    padding-top: 5px;
    padding-bottom: 30px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-direction: column-reverse;
    max-width: calc(670px * 2 / 3);

    .tempat-waktu-bg {
      width: 90%;
    }

    .tempat-waktu-desc {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 50px;
      padding-right: 50px;
    }

    .tw-longdesc {
      width: 100%;
    }
  }
`
