// Generic imports
import React from "react"
import PropTypes from "prop-types"

// Other components imports
import Image from "components/image"
import { Link } from "gatsby"
import { Styles } from "components/Classes/ClassesCard/style"

// Redux imports

// Static imports

// Utils imports

const ClassCard = props => {
  const classType =
    props.type === "TS"
      ? "TamanClass Seikhlasnya"
      : props.type === "TP"
      ? "TamanClass+"
      : props.type === "AP"
      ? "AfterClass+"
      : "AfterClass Seikhlasnya"

  return (
    <Styles>
      <div className="card-container">
        {props.imageComponent}
        <div className="class-content">
          <div className="card-header">
            <Link to={`/kelas/${props.code}`}>
              <h4>{props.title}</h4>
            </Link>
            {props.typeOnTitle ? (
              <div>
                <Image
                  imgName={`${classType}.png`}
                  alt={classType}
                  className="class-type"
                  onClick={() => props.onClickTipe(classType)}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            ) : (
              <></>
            )}
            <div className="extras">{props.header}</div>
          </div>
          <p className="class-author">
            by <a>{props.author}</a>
            {props.authorTitle !== "" ? `, ${props.authorTitle}` : <></>}
          </p>
          {!props.typeOnTitle ? (
            <Image
              imgName={`${classType}.png`}
              alt={classType}
              className="class-type"
              onClick={() => props.onClickTipe(classType)}
              style={{ top: `8px`, marginLeft: 0, marginBottom: `24px` }}
            />
          ) : (
            <></>
          )}
          {props.content}
          {props.footer}
        </div>
      </div>
    </Styles>
  )
}

ClassCard.propTypes = {
  author: PropTypes.string.isRequired,
  authorTitle: PropTypes.string,
  code: PropTypes.number.isRequired,
  content: PropTypes.element,
  footer: PropTypes.element,
  header: PropTypes.element.isRequired,
  onClickType: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  typeOnTitle: PropTypes.bool,
}

ClassCard.defaultProps = {
  authorTitle: "",
  typeOnTitle: true,
}

export default ClassCard
