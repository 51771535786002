/* eslint-disable no-unused-vars */
// Generic imports
import React, { Component } from "react"

// Other components imports
import OldClassCard from "components/Classes/OldClassCard"
// import DashboardCard from "components/Classes/ClassesCard/DashboardCard"
// import DashboardMiniCard from "components/Classes/ClassesCard/DashboardMiniCard"
import { Col, Dropdown, Pagination, Row } from "react-bootstrap"
import Button from "components/Button"
import Image from "components/image"
import MarketplaceCard from "components/Classes/ClassesCard/MarketplaceCard"
import { Styles } from "components/Classes/SearchBox/style"
import SearchNotFound from "components/SearchNotFound"

// Redux imports

// Static imports
import ITIcon from "images/classes/categories/it.svg"
import AcademicIcon from "images/classes/categories/academic.svg"
import CreativeIcon from "images/classes/categories/creative.svg"
import DesignIcon from "images/classes/categories/design.svg"
import LanguageIcon from "images/classes/categories/language.svg"
import ManagementIcon from "images/classes/categories/management.svg"

// Utils imports
import { getAllClasses } from "services/kelas"
import { getParameterByName, isPastDate, isBrowser } from "services/utils"
import { trackClassesViewed } from "trackers/amplitude"
import NewClassCard from "../NewClassCard"

const CLASS_PER_PAGE = 8

const categoriesData = [
  {
    name: "Academic",
    icon: AcademicIcon,
  },
  {
    name: "Creative",
    icon: CreativeIcon,
  },
  {
    name: "Language",
    icon: LanguageIcon,
  },
  {
    name: "Management",
    icon: ManagementIcon,
  },
  {
    name: "Design",
    icon: DesignIcon,
  },
  {
    name: "Information and Technology",
    icon: ITIcon,
  },
]

const reservedCategories = {
  academic: "AC",
  creative: "CR",
  language: "LA",
  management: "MA",
  design: "DE",
  "information and technology": "IT",
}

class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleCategoriesClick = this.handleCategoriesClick.bind(this)
    this.state = {
      activePage: 1,
      allClasses: [],
      showedClasses: [],
      selectValue: "All Class",
      prevSearch: "",
      categories: [],
    }
  }

  componentDidMount() {
    trackClassesViewed()
    getAllClasses().then(resp => {
      const data = resp.data
      data?.sort((a, b) => {
        const aClosed = isPastDate(new Date(a.registration_due))
        const bClosed = isPastDate(new Date(b.registration_due))
        if (!aClosed && bClosed) return -1
        if (aClosed && !bClosed) return 1
        if (a.is_verified && !b.is_verified) return -1
        if (!a.is_verified && b.is_verified) return 1
        return 0
      })
      this.setState(
        {
          activePage: 1,
          allClasses: data,
          showedClasses: [],
          selectValue: "All Class",
          prevSearch: "",
        },
        () => {
          const query = getParameterByName("q")
          if (query) {
            if (query === "TC") {
              this.handleDropdown("TC")
            } else if (query === "TP") {
              this.handleDropdown("TP")
            } else if (query === "TS") {
              this.handleDropdown("TS")
            } else {
              this.handleTagClick(query)()
            }
          }
        }
      )
    })
  }

  checkFilters(filter) {
    return this.state.allClasses.filter(kelas => {
      return filter === "All Class" || kelas.type === filter
    })
  }

  handleDropdown = eventKey => {
    this.handleChange({
      target: { name: "dropdown", value: eventKey },
    })
  }

  handleTagClick = toSearch => () => {
    this.handleChange({
      target: { name: "searchbox", value: toSearch },
    })
  }

  handleCategoriesClick(e) {
    const value = e.target.innerText
    // console.log(value)
    // console.log(this.state.categories)
    const selectedCategories = this.state.categories
    // console.log(selectedCategories)

    if (selectedCategories.includes(value)) {
      selectedCategories.splice(selectedCategories.indexOf(value), 1)
    } else {
      selectedCategories.push(value)
    }

    this.setState({
      categories: selectedCategories,
    })
  }

  handleChange(e) {
    let { selectValue, prevSearch } = this.state
    let filteredClasses = []
    let toMatch = ""

    if (e.target.name === "dropdown") {
      toMatch = this.state.prevSearch.toLowerCase()
      selectValue = e.target.value
    } else if (e.target.name === "searchbox") {
      toMatch = e.target.value.toLowerCase()
      prevSearch = e.target.value
    } else {
      toMatch = this.state.prevSearch.toLowerCase()
      prevSearch = ""
    }

    filteredClasses = this.checkFilters(selectValue)

    if (toMatch !== "") {
      if (toMatch in reservedCategories) {
        const matchCategory = reservedCategories[toMatch]
        filteredClasses = filteredClasses.filter(
          currentClass => currentClass.category === matchCategory
        )
      } else {
        filteredClasses = filteredClasses.filter(currentClass => {
          const matchableName = currentClass.name.toLowerCase()
          const matchableDescription = currentClass.description.toLowerCase()
          const tutorName =
            currentClass.owner.first_name + " " + currentClass.owner.last_name
          const matchableTutor = tutorName.toLowerCase()
          if (
            matchableName.includes(toMatch) ||
            matchableDescription.includes(toMatch) ||
            matchableTutor.includes(toMatch) ||
            currentClass.tags.join(" ").includes(toMatch)
          ) {
            return true
          }
          return false
        })
      }
    } else if (toMatch === "") {
      filteredClasses = []
    }

    // check whether activePage is out of bound
    let { activePage } = this.state
    const maxPage =
      Math.floor((filteredClasses.length - 1) / CLASS_PER_PAGE) + 1
    if (activePage > maxPage) activePage = maxPage

    this.setState({
      selectValue: selectValue,
      showedClasses: filteredClasses,
      activePage: activePage,
      prevSearch: prevSearch,
    })
  }

  handlePageChange(e) {
    if (!e.target.text) return
    if (e.target.text === ">") {
      if (
        this.state.activePage !==
        Math.floor((this.state.showedClasses.length - 1) / CLASS_PER_PAGE) + 1
      ) {
        this.setState({ activePage: this.state.activePage + 1 })
      }
    } else if (e.target.text === "<") {
      if (this.state.activePage !== 1) {
        this.setState({ activePage: this.state.activePage - 1 })
      }
    } else {
      this.setState({ activePage: Number(e.target.text) })
    }
  }

  render() {
    const { whatsOn } = this.props
    const {
      showedClasses,
      allClasses,
      activePage,
      selectValue,
      prevSearch,
      categories,
    } = this.state

    const pageNumbers = []
    const temp = []
    const maxPage = Math.floor((showedClasses.length - 1) / CLASS_PER_PAGE) + 1

    pageNumbers.push(<Pagination.Item key="<">{"<"}</Pagination.Item>)

    for (let pageNumber = 1; pageNumber <= maxPage; pageNumber++) {
      temp.push(
        <Pagination.Item key={pageNumber} active={pageNumber === activePage}>
          {pageNumber}
        </Pagination.Item>
      )
    }

    const minLimit = () => {
      if (activePage === 1) {
        return activePage - 1
      } else if (activePage === maxPage && maxPage > 2) {
        return activePage - 3
      } else {
        return activePage - 2
      }
    }

    const maxLimit = () => {
      if (activePage <= maxPage - 2 && activePage === 1) {
        return activePage + 2
      } else if (activePage <= maxPage - 2) {
        return activePage + 1
      } else {
        return maxPage
      }
    }

    temp
      .slice(minLimit(), maxLimit())
      .map(pageNumber => pageNumbers.push(pageNumber))

    pageNumbers.push(<Pagination.Item key=">">{">"}</Pagination.Item>)

    let slicedShowedClasses

    if (this.state.activePage === 0) {
      this.setState({ activePage: 1 })
      slicedShowedClasses = showedClasses.slice(
        (activePage - 1) * CLASS_PER_PAGE,
        activePage * CLASS_PER_PAGE
      )
    } else {
      slicedShowedClasses = showedClasses.slice(
        (activePage - 1) * CLASS_PER_PAGE,
        activePage * CLASS_PER_PAGE
      )
    }

    const reviewComponent = (
      <div className="w-100 mt-5 px-md-0 px-2">
        {whatsOn.length > 0 ? (
          <>
            <div className="subtitle">What is on TamanSchool?</div>
            <div
              className="d-flex my-2 mx-0 w-100 whatson-container"
              style={{ columnGap: "0.875rem" }}
            >
              {whatsOn.map((e, idx) => (
                <img
                  key={idx}
                  src={e.foto}
                  className="whatson-image"
                  onClick={() => window.open(e.link, "_self")}
                />
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    )

    return (
      <Styles>
        <div className="my-5 section-container px-md-4 px-2">
          <div className="search-container">
            <div className="search-box">
              <input
                type="text"
                name="searchbox"
                className="class-search"
                value={prevSearch}
                onChange={this.handleChange}
                placeholder="Mau belajar apa?"
              ></input>
              <div className="looking-glass">
                <div className="looking-img-wrapper">
                  <Image imgName="lup.png" alt="looking-glass" />
                </div>
              </div>
              <Button
                className="share-button"
                style={{ marginLeft: "1rem" }}
                onClick={() =>
                  this.setState({ shareBox: window.location.href })
                }
              >
                Cari
              </Button>
            </div>
            {/* DELAYED */}
            {/* <Row className="categories-flex">
              <Col sm={12} md={1} className="categories-title mb-md-0 mb-3">
                Categories
              </Col>
              <Col style={{ display: "flex", flexWrap: "wrap" }}>
                {categoriesData.map((item, index) => (
                  <div
                    onClick={this.handleCategoriesClick}
                    key={index}
                    className={`categories-wrapper mb-md-0 mb-2 ${
                      categories.includes(item.name) ? "active" : ""
                    }`}
                  >
                    <div className="categories-icon-wrapper">
                      <img src={ITIcon} alt="" style={{ width: "16px" }} />
                    </div>
                    <span>{item.name}</span>
                  </div>
                ))}
              </Col>
            </Row> */}
          </div>
          {slicedShowedClasses.length > 0 ? (
            <div className="w-100 mt-5 px-md-0 px-2">
              <div className="subtitle">Search Result</div>
              <div className="w-100 card-list">
                {slicedShowedClasses.map((kelas, id) => {
                  const onlyStarRating = kelas.rating.map(e => e.bintang)
                  const allRating =
                    onlyStarRating.reduce((prev, curr) => prev + curr, 0) /
                    onlyStarRating.length

                  const classMember =
                    kelas.paket_pembayaran?.length > 0
                      ? kelas.paket_pembayaran.reduce(
                          (prev, curr) => prev + curr.banyak_pendaftar,
                          0
                        )
                      : 0
                  const classPrice =
                    kelas.paket_pembayaran?.length > 0
                      ? kelas.paket_pembayaran.reduce((prev, curr) => {
                          const prevPrice = prev.harga_per_orang_sesi
                          const currPrice = curr.harga_per_orang_sesi
                          return prevPrice > currPrice ? curr : prev
                        }).harga_per_orang_sesi
                      : 0

                  return (
                    <NewClassCard
                      key={id}
                      className="card-class"
                      code={kelas.slug}
                      image={kelas.logo}
                      type={kelas.type}
                      title={kelas.name}
                      author={
                        kelas.owner.first_name + " " + kelas.owner.last_name
                      }
                      description={kelas.description}
                      category="Information and Technology"
                      tags={kelas.tags}
                      price={classPrice}
                      member={classMember}
                      rating={allRating ? allRating.toFixed(1) : "-"}
                      onClickTag={this.handleTagClick}
                      onClickTipe={this.handleDropdown}
                    />
                  )
                })}
              </div>
              {pageNumbers.length > 2 ? (
                <Pagination onClick={this.handlePageChange}>
                  {pageNumbers}
                </Pagination>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <>
              {prevSearch !== "" && (
                <SearchNotFound keyword={prevSearch} className="mt-5" />
              )}
              {reviewComponent}
            </>
          )}

          <div className="w-100 mt-5 px-md-0 px-2">
            <div className="subtitle mb-3">Search by Category</div>
            <Row className="mx-0">
              {categoriesData.map((item, index) => (
                <Col sm={6} md={4} key={index} style={{ padding: "5px" }}>
                  <div
                    className="categories-search-box"
                    onClick={() => {
                      this.handleTagClick(item.name)()
                      if (typeof document !== "undefined") {
                        document
                          .querySelector("body")
                          .scrollTo({ top: 0, left: 0, behavior: "smooth" })
                      }
                    }}
                  >
                    <div className="categories-icon-wrapper">
                      <img
                        src={item.icon}
                        alt={item.name}
                        style={{ width: "16px" }}
                      />
                    </div>
                    <span>{item.name}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <div className="w-100 mt-5 px-md-0 px-2">
            <div className="subtitle">Classes You Might Like</div>
            <div className="w-100 card-list">
              {allClasses?.slice(0, 8).map((kelas, id) => {
                const onlyStarRating = kelas.rating.map(e => e.bintang)
                const allRating =
                  onlyStarRating.reduce((prev, curr) => prev + curr, 0) /
                  onlyStarRating.length

                const classMember =
                  kelas.paket_pembayaran?.length > 0
                    ? kelas.paket_pembayaran.reduce(
                        (prev, curr) => prev + curr.banyak_pendaftar,
                        0
                      )
                    : 0
                const classPrice =
                  kelas.paket_pembayaran?.length > 0
                    ? kelas.paket_pembayaran.reduce((prev, curr) => {
                        const prevPrice = prev.harga_per_orang_sesi
                        const currPrice = curr.harga_per_orang_sesi
                        return prevPrice > currPrice ? curr : prev
                      }).harga_per_orang_sesi
                    : 0

                return (
                  <NewClassCard
                    key={id}
                    className="card-class"
                    code={kelas.slug}
                    image={kelas.logo}
                    type={kelas.type}
                    title={kelas.name}
                    author={
                      kelas.owner.first_name + " " + kelas.owner.last_name
                    }
                    description={kelas.description}
                    category="Information and Technology"
                    tags={kelas.tags}
                    price={classPrice}
                    member={classMember}
                    rating={allRating ? allRating.toFixed(1) : "-"}
                    onClickTag={this.handleTagClick}
                    onClickTipe={this.handleDropdown}
                  />
                )
              })}
            </div>
          </div>

          {slicedShowedClasses.length > 0 ? reviewComponent : <></>}
        </div>
      </Styles>
    )
  }
}

export default SearchBox
